import styled from 'styled-components';

export const ConvidadoNome = styled.p`
    color: ${p => p.theme.dark};
    font-size: 1.2rem;
    margin-bottom: 4px;
`;
export const ConvidadoEmail = styled.small`
    color: ${p => p.theme.dark};
    opacity: .6;
`;