import Api from "./Api";

class LogService {
    listarLog(id, result) {
        Api.get(`/Usuario/Log/Listar/${id}`)
        .then(res => {
            result(res.data);
        })
        .catch(err => {
            console.log(err.response)
        })
    }
}

export default new LogService();