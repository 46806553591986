import React, { useEffect, useState } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { toast } from 'react-toastify';
import NotificationService from '../../Services/NotificationService';

function Notificacao() {
    const [connect, setConnect] = useState();

    useEffect(() => {
        const connection = new HubConnectionBuilder()
            .withUrl("/ws")
            .withAutomaticReconnect()
            .build();
        setConnect(connection);
    }, [])

    useEffect(() => {
        if (connect) {
            connect.start()
                .then(res => {
                    console.log(`Conexão iniciada: ${connect.connectionId}`);
                    NotificationService.salvandoConexao(connect.connectionId);

                    connect.on("NEWLOG", (result) => {
                        if (result !== null)
                            toast(result);
                        console.log({
                            asdasd: result
                        })
                    })

                })

            connect.onreconnected(() => {
                NotificationService.excluindoConexao(connect.connectionId);
                setTimeout(() => {
                    NotificationService.salvandoConexao(connect.connectionId);
                }, 1000)
            })


        }
    }, [connect])

    return (<></>);
};

export default Notificacao;