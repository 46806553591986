import React, { useContext, useState } from 'react';
import { AiFillCamera } from 'react-icons/ai'
import { ThemeContext } from 'styled-components';
import { CameraItemContainer, CameraStatus, BtnExit, SmallCameraItem, DescricaoCameraItem } from './Style';
import { BsFillTrash2Fill } from 'react-icons/bs';
import CModal from '../CModal/Index';
import Botao from '../Botao/Index';
import PIService from '../../Services/PIService';
import { Tooltip } from 'reactstrap';

function CameraItem({
    codigo,
    status,
    id,
    descricao,
    activeExit,
    onClick
}) {
    const { dark } = useContext(ThemeContext)
    const [modalExit, setModalExit] = useState(false);
    const [exitLoad, setExitLoad] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    function excluirPI() {
        setExitLoad(true)
        PIService.excluirPI(
            id,
            () => {
                alert("Câmera excluída com sucesso!");
                setModalExit(false);
                setExitLoad(false);
            },
            () => {
                alert("Houve um erro")
                setExitLoad(false);
            }
        )
    }

    return (
        <>
            <CameraItemContainer onClick={onClick}>
                <BtnExit exit={activeExit} onClick={() => setModalExit(true)}>
                    <BsFillTrash2Fill color="#fff" size={14} />
                </BtnExit>
                <div className="d-flex">
                    <AiFillCamera color={dark} size={28} />
                    <div className="ml-2">
                        <DescricaoCameraItem>
                            {descricao}
                        </DescricaoCameraItem>
                        <SmallCameraItem>
                            {codigo}
                        </SmallCameraItem>
                    </div>
                </div>
                <Tooltip isOpen={tooltipOpen} target={codigo} placement="left" toggle={toggle} >
                    {status}
                </Tooltip>
                <CameraStatus status={status} id={codigo} />
            </CameraItemContainer>
            <CModal
                isOpen={modalExit}
                action={
                    <div className="d-flex justify-content-around">
                        <Botao
                            text="Cancelar"
                            onClick={() => setModalExit(false)}
                            tema="secondary"
                            outline
                        />
                        <Botao
                            text="Excluir câmera"
                            onClick={() => excluirPI()}
                            load={exitLoad}
                        />
                    </div>
                }
            >
                <p>
                    Excluindo a câmera removerá o vínculo do dispositivo com essa pi, excluir mesmo assim?
                </p>
            </CModal>
        </>
    );
}

export default CameraItem;