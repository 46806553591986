import Api from "./Api";

class NotificationService {

    salvandoConexao(connectionId) {
        const data = {
            connectionId
        }
        Api.post("/Instituicao/Connection", data)
    }

    excluindoConexao(connectionId) {
        Api.delete(`/Instituicao/Connection/${connectionId}`)
    }

}

export default new NotificationService();