import styled, { css } from 'styled-components';


export const SidebarContainer = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  background: ${p => p.theme.dark};
  display: flex;
`;

export const SidebarSide = styled.div`
 @keyframes show {
          0% {
            width: 60px;
          }
          100% {
            width: 180px;
          }
      }
      @keyframes hidden {
          0% {
            width: 180px;
          }
          100% {
            width: 60px;
          }
      }
  height: 100vh;
  width: 60px;
  height: 100vh;
  ${p => p.open && css`
     animation: show .6s ;
     animation-fill-mode: forwards;
  `};
    ${p => !p.open && css`
    animation: hidden .6s ;
    animation-fill-mode: forwards;
  `};
`;

export const MainContext = styled.div`
    border-radius: 32px 0 0 32px;
    /* background: red; */
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
`;

export const ContextContainer = styled.div`
  @keyframes opens {
    0% {
      left: -520px;
    }
    100% {
      left: 0;
    }
  }
  @keyframes hiddens {
    0% {
      left: 0;
    }
    100% {
      left: -520px;
    }
  }

  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  position: absolute;
  ${p => p.open && css`
      animation: opens ease .6s;
      animation-fill-mode: forwards;
  `}
  ${p => !p.open && css`
      animation: hiddens ease .6s;
      animation-fill-mode: forwards;
  `}
`;

export const ContextSidebar = styled.div`
    &::-webkit-scrollbar {
      width: 6px;
      height: 4px;
      position: absolute;
      margin: 2px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb  {
      border-radius: 12px;
      background-color: ${p => p.theme.dark};
    }

    overflow-y: auto;
    width: 520px;
    height: 100vh;
    background: #fff;
    z-index: 1;
    padding: 20px;
`;

export const SidebarButton = styled.button`
    width: 100%;
    height: 64px;
    display: flex;
    align-items:center;
    justify-content: center;
    background: transparent !important;
    border: none !important;
`;

export const BtnClose = styled.button`
    z-index:9;
    background: transparent;
    border: transparent;
    position: relative;
    background: #fff;
    padding: 20px;
    border-radius: 0 18px 0 0;
`;

export const ImageInstituicao = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;

  img {
    width: 64px;
    height: 64px;
  }
`;