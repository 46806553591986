import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import Sidebar from './Components/Sidebar/Index';
import Admin from './Pages/Admin/Admin';
import Convidados from './Pages/Convidados/Index';
import Locais from './Pages/Locais/Index';
import Local from './Pages/Locais/Local';
import RegistrarLocal from './Pages/Locais/Registrar';
import Login from './Pages/Login';
import LP from './Pages/LP';
import TelaPrincipal from './Pages/TelaPrincipal';
import RouteController from './Services/RouteController';
import { BGContext } from './Styles/GlobalComponentStyle';
import { GlobalStyle } from './Styles/GlobalStyle';
import theme from './Styles/theme';
import Policy from './Pages/Policy';
const App = () => {

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        toastStyle={{
          top: 80
        }}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover
      />
      <ThemeProvider theme={theme}>
        <BGContext />
        <GlobalStyle />
        <Switch>
          <Route component={LP} exact path="/" />
          <Route component={Login} exact path="/login" />
          <Route component={Admin} exact path="/admin" />
          <Route component={Policy} path="/Policy" exact />
          <Sidebar>
            <RouteController component={TelaPrincipal} exact path="/instituicao" />
            <RouteController component={Convidados} exact path="/convidados" />
            <RouteController component={Locais} exact path="/locais" />
            <RouteController component={Local} path="/local/:id" exact />
            <RouteController component={RegistrarLocal} path="/NovoLocal" exact />
          </Sidebar>
        </Switch>
      </ThemeProvider>
    </>
  );
}

export default App;
