import React, { useContext, useEffect, useState } from 'react';
import { BotaoPrincipalContainer, BotaoPrincipalText } from '../Components/Botao/Styles';
import { HiMap } from 'react-icons/hi'
import { FaUsers } from 'react-icons/fa'
import { BiExit } from 'react-icons/bi'
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import MapaView from '../Components/MapaView/MapaView';
import { TitleAbbsolute } from '../Styles/GlobalComponentStyle';
import NoticiaItem from '../Components/NoticiaItem/Index';
import InstituicaoService from '../Services/InstituicaoService';

const TelaPrincipal = () => {
  const { secondary } = useContext(ThemeContext)
  const [inst, setInst] = useState();
  const history = useHistory();
  function BotaoPrincipal({ icon, text, onClick }) {
    return (
      <BotaoPrincipalContainer onClick={onClick}>
        {icon}
        <BotaoPrincipalText>{text}</BotaoPrincipalText>
      </BotaoPrincipalContainer>
    )
  }

  useEffect(() => {
    InstituicaoService.getInstituicao(
      (e) => setInst(e)
    )
  }, [])

  return (
    <>
      <h5>Notícias dos usuários</h5>
      <NoticiaItem text="Eduardo se cadastrou na instituição" data="25/05/2021 18:30" />
    </>
  );
}

export default TelaPrincipal;