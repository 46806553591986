import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Botao from '../../Components/Botao/Index';
import Input from '../../Components/Input/Index';
import InputSearch from '../../Components/Input/InputSearch';
import LocalService from '../../Services/LocalService';
import CepCoords from 'coordenadas-do-cep';
import { ThemeContext } from 'styled-components';
import { getRandom } from '../../Functions/randomNumber';
import { toast } from 'react-toastify'

function RegistrarLocal() {
    //DADOS
    const [nome, setNome] = useState("");
    const [CEP, setCEP] = useState("");
    const [bairro, setBairro] = useState("");
    const [estado, setEstado] = useState("");
    const [logradouro, setLogradouro] = useState("");
    const [localidade, setLocalidade] = useState("");
    const [complemento, setComplemento] = useState("");
    const [ddd, setDdd] = useState("");
    const [telefone, setTelefone] = useState("");
    const [numero, setNumero] = useState('');
    //OUTROS
    const [location, setLocation] = useState();
    const history = useHistory();
    const { primary } = useContext(ThemeContext);
    const [random, setRandom] = useState({
        lat: getRandom(-25, 1),
        lon: getRandom(-70, -30)
    })
    const [load, setLoad] = useState(false);
    CepCoords.setOpcoes({
        busca_aproximada: true,
        precisao: 10,
        casas_dec_dist: 1
    })

    function preencherPeloCEP() {
        if (CEP.length === 8 && !CEP.match(/([a-zA-Z])/g)) {
            LocalService.getEnderecoPeloCEP(
                CEP,
                (e) => {
                    setBairro(e.bairro)
                    setDdd(e.ddd)
                    setLocalidade(e.localidade)
                    setLogradouro(e.logradouro)
                    setEstado(e.uf)
                    CepCoords.getByEndereco(e.uf + ', ' + e.localidade + ' ' + e.bairro + ' ' + e.logradouro + ' ' + numero)
                        .then(e => setLocation(e))
                }
            )
        } else {
            toast.warning("CEP inválido.")
        }
    }

    function RegistrarLocal() {
        setLoad(true)
        if (CEP.length === 8 && !CEP.match(/([a-zA-Z])/g)) {
            LocalService.registrarLocal(
                nome,
                CEP,
                logradouro,
                localidade,
                estado,
                complemento,
                parseInt(ddd),
                telefone,
                bairro,
                parseInt(numero),
                location?.lat,
                location?.lon,
                () => {
                    alert('Local criado com sucesso!')
                    history.goBack();
                    setLoad(false)
                },
                () => {
                    setLoad(false)
                }
            )
        } else {
            toast.warning("CEP inválido.")
            setLoad(false)
        }
    }

    return (
        <>
            <div className="row">
                <div className="container p-5">
                    <h2>Novo Local</h2>
                    <Input
                        text="Nome do local"
                        onChange={(e) => setNome(e.target.value)}
                        value={nome}
                    />
                    <InputSearch
                        text="CEP"
                        onChange={(e) => setCEP(e.target.value)}
                        value={CEP}
                        maxWidth
                        onClick={() => preencherPeloCEP()}
                    />

                    <hr />

                    <Input
                        text="Bairro"
                        onChange={(e) => setBairro(e.target.value)}
                        value={bairro}
                    />

                    <div className="d-flex justify-content-between">

                        <div className="mr-1">
                            <Input
                                text="Estado"
                                onChange={(e) => setEstado(e.target.value)}
                                value={estado}
                            />
                        </div>

                        <div className="ml-1">
                            <Input
                                text="Logradouro"
                                onChange={(e) => setLogradouro(e.target.value)}
                                value={logradouro}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-between">
                        <div className='mr-1'>
                            <Input
                                text="Número"
                                type="number"
                                onChange={(e) => setNumero(e.target.value)}
                                value={numero}
                            />
                        </div>
                        <div className="ml-1">
                            <Input
                                text="Localidade"
                                onChange={(e) => setLocalidade(e.target.value)}
                                value={localidade}
                            />
                        </div>

                    </div>

                    <Input
                        text="Complemento (opcional)"
                        onChange={(e) => setComplemento(e.target.value)}
                        value={complemento}
                    />
                    <div className="d-flex justify-content-between">
                        <div className='mr-1'>
                            <Input
                                text="DDD"
                                onChange={(e) => setDdd(e.target.value)}
                                value={ddd}
                            />
                        </div>
                        <div className='ml-1'>
                            <Input
                                text="Telefone"
                                onChange={(e) => setTelefone(e.target.value)}
                                value={telefone}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-between">
                        <Botao
                            outline
                            tema="secondary"
                            text="Cancelar"
                            onClick={() => history.goBack()}
                        />
                        <Botao
                            tema="primary"
                            text="Registrar local"
                            onClick={() => RegistrarLocal()}
                            load={load}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default RegistrarLocal;