import React, { useContext } from 'react';
import { InputSearchInput, InputSearchButton, InputSearchContainer, InputSearchText } from './Style';
import { BiSearch } from 'react-icons/bi'
import { ThemeContext } from 'styled-components';

function InputSearch({
    text,
    onChange,
    value,
    onClick,
    maxWidth
}) {
    const { primary } = useContext(ThemeContext)
    return (
        <div>
            <InputSearchText>{text}</InputSearchText>
            <InputSearchContainer>
                <InputSearchInput placeholder="..." onChange={onChange} value={value} maxWidth={maxWidth} />
                <InputSearchButton onClick={onClick}>
                    <BiSearch color={primary} size={24} />
                </InputSearchButton>
            </InputSearchContainer>
        </div>
    );
}

export default InputSearch;