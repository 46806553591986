import React, { useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom'
import { Validate } from '../Functions/Validate';

const RouteController = ({
    component,
    path,
    exact,
    roles = ["Owner"]
}) => {

    if (Validate()) {
        return (
            <Route component={component} path={path} exact={exact} />
        );
    } else {
        return (
            <Redirect to="/login" />
        );
    }
}

export default RouteController;