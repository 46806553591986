import React, { useState } from 'react';
import InputSearch from '../../Components/Input/InputSearch';
import Botao from '../../Components/Botao/Index'
import ConvidadoItem from '../../Components/ConvidadoItem/Index';

const Convidados = () => {
  const [search, setSearch] = useState("");
  return (
    <>
      <div className="container pt-4">
        <div className="d-flex justify-content-between align-items-end pb-4">
          <div className="w-50">
            <InputSearch
              text="Insira o email do convidado"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onClick={() => alert("Pesquisar")}
            />
          </div>
          <div>
            <Botao
              text="Registrar convidado"
              onClick={() => alert("Registrar")}
            />
          </div>
        </div>
        <div>
          <ConvidadoItem nome="Eduardo" email='eduardo14rj@gmail.com' id={4} />
        </div>
      </div>
    </>
  );
}

export default Convidados;