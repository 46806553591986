import { Map } from 'pigeon-maps';
import React from 'react';
const MapaView = ({
    lat,
    lng,
    width,
    height,
    zoom = 10,
    children
}) => {
    return (
        <Map
            width={width}
            animate
            height={height}
            maxZoom={18}
            dprs={[1, 2]}
            minZoom={6}
            center={[lat, lng]}
            zoom={zoom}   
        >
            {children}
        </Map>
    );
}

export default MapaView;