import React from 'react';
import { useHistory } from 'react-router-dom'

const LP = () => {
    const history = useHistory();

    return (
        <div>
            landing page
            <button onClick={() => history.push('/login')}>
                Entrar agora
            </button>
        </div>
    );
}

export default LP;