import styled, { css } from 'styled-components';

export const CameraItemContainer = styled.div`
    background: #fff;
    border: solid 2px ${p => p.theme.dark};
    border-radius: .38rem;
    padding:8px;
    position: relative;
    font-size: .90rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const CameraStatus = styled.div`
    width: 18px;
    cursor: pointer;
    height: 18px;
    border-radius: 100%;
    position: absolute;
    right: 8px;
    ${p => p.status === "Desvinculado" && css`
        background: ${p.theme.secondary};
    `}
    ${p => p.status === "Desativado" && css`
        background: #888;
    `}
    ${p => p.status === "Ativado" && css`
        background: ${p.theme.primary};
    `}
`

export const BtnExit = styled.button`
    @keyframes scala {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes scala_reverse {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    transform: scale(0);
    position: absolute;
    top: -16px;
    right: -16px;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${p => p.theme.secondary};
    border: none !important;
    outline: none !important;
    ${p => p.exit ? css`
    animation: scala .25s ease;
    animation-fill-mode: forwards;
    ` : css`
    animation: scala_reverse .25s ease;
    animation-fill-mode: forwards;
    `}
`;

export const SmallCameraItem = styled.p`
    font-size: .64rem;
    opacity: .72;
    font-weight: bold;
    margin-bottom: 0;
`;

export const DescricaoCameraItem = styled.p`

    font-size: 1rem;
    width: calc(100% - 18px) !important;
    margin-bottom: 4px;
`