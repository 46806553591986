import React, { useContext, useState } from 'react';
import Botao from '../Components/Botao/Index';
import { AuthContainer } from '../Styles/StylePages/AuthStyle';
import img from '../img/img-auth.png';
import Input from '../Components/Input/Index';
import { useHistory } from 'react-router-dom';
import AuthService from '../Services/AuthService';
import CModal from '../Components/CModal/Index';
import {AiOutlineMail, AiFillPhone} from 'react-icons/ai'
import { ThemeContext } from 'styled-components';
import { Contact } from '../Styles/GlobalComponentStyle';

function Login() {
    const history = useHistory();
    const [login, setLogin] = useState("");
    const [senha, setSenha] = useState("");
    const [load, setLoad] = useState(false);
    const [open, setOpen] = useState(false);
    const {primary} = useContext(ThemeContext);

    function Logar() {
        setLoad(true)
        AuthService.Logar(
            login,
            senha,
            () => {
                setLoad(false)
                history.push('/locais')
            },
            () => { setLoad(false) }
        )
    }

    return (
        <>
            <AuthContainer>
                <div className="container col-12 col-md-4">
                    <img src={img} alt="auth" className="w-100" />
                    <Input
                        text="Login"
                        type="email"
                        onChange={(e) => setLogin(e.target.value)}
                    />
                    <Input
                        text="Senha"
                        type="password"
                        onChange={(e) => setSenha(e.target.value)}
                    />
                    <div className="d-flex justify-content-between">
                        <Botao
                            text="Contate-nos para se cadastrar"
                            outline
                            onClick={() => setOpen(true)}
                            tema="secondary"
                        />
                        <Botao
                            text="Entrar"
                            tema="secondary"
                            onClick={() => Logar()}
                            load={load}
                        />
                    </div>
                </div>
            </AuthContainer>
            <CModal
                isOpen={open}
                action={
                    <div className="d-flex align-items-end">
                        <Botao text="Entendi" onClick={() => setOpen(false)} />
                    </div>
                }
            >
                <p>
                Entre em contato conosco.
                </p>
                <Contact>
                    <AiOutlineMail color={primary} size={20} />
                    <p>contato@gmail.com</p>
                </Contact>

                <Contact>
                    <AiFillPhone color={primary} size={20} />
                    <p>89035-9933</p>
                </Contact>

            </CModal>
        </>
    );
}

export default Login;