import React, { useContext, useEffect, useState } from 'react';
import { CardGhost, Scroll } from '../../Styles/GlobalComponentStyle';
import { AiFillCamera } from 'react-icons/ai'
import { ThemeContext } from 'styled-components';
import Botao from '../../Components/Botao/Index';
import CameraItem from '../../Components/CameraItem/Index';
import NoticiaItem from '../../Components/NoticiaItem/Index';
import LocalService from '../../Services/LocalService';
import { useParams } from 'react-router-dom';
import CModal from '../../Components/CModal/Index';
import Input from '../../Components/Input/Index';
import PIService from '../../Services/PIService';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import LogService from '../../Services/LogService';

const Local = () => {
    const [modalRegisterPI, setModalRegisterPI] = useState(false);
    const { dark } = useContext(ThemeContext)
    const { id } = useParams();
    const [dates, setDates] = useState();
    const [descricao, setDescricao] = useState("");
    const [load, setLoad] = useState(false)
    const [pi, setPi] = useState([]);
    const [activeExit, setActiveExit] = useState(false);
    const [drop, setDrop] = useState(false);
    const [logs, setLogs] = useState([]);
    const [PIEscolhido, setPIEscolhido] = useState("");
    function listarPIs() {
        setPi([]);
        PIService.listarPIs(
            id,
            (res) => { setPi(res) }
        )
    }

    useEffect(() => {
        LocalService.getLocal(
            parseInt(id),
            (e) => {
                setDates(e);
            },
            () => { }
        )
        listarPIs();
    }, [])

    function listarLogs(idPI) {
        console.log(idPI)
        setLogs([]);
        LogService.listarLog(
            idPI,
            (e) => { if (e) setLogs(e) }
        )
    }

    function criarPI() {
        setLoad(true)
        PIService.registrarPI(
            id,
            descricao,
            () => {
                setModalRegisterPI(false)
                setLoad(false)
                alert("Ponto de Identificacao criada com sucesso!")
                listarPIs();
            },
            () => {
                setModalRegisterPI(false)
                setLoad(false)
            }
        )
    }

    return (
        <>
            <div className="container pt-3">
                <h2>{dates?.nome}</h2>
                <p className="text-black-50 mb-5">{`${dates?.localidade}, ${dates?.logradouro}`}</p>
                <div className="row">
                    <div className="col-md-12">
                        <CardGhost className="mb-3">
                            <div className="d-flex align-items-center">
                                <AiFillCamera color={dark} size={38} />
                                <p className="m-0 ml-3">São {pi?.cameras} câmeras registradas nesse local</p>
                            </div>
                            <div className="d-flex justify-content-end">
                                {/* <div className="mr-2">
                                    <Botao
                                        text={activeExit ? "Cancelar" : "Excluir PI"}
                                        tema="secondary"
                                        onClick={() => setActiveExit(!activeExit)}
                                    />
                                </div> */}
                                <Botao
                                    text="Adicionar PI"
                                    tema="secondary"
                                    onClick={() => setModalRegisterPI(true)}
                                />
                            </div>
                        </CardGhost>
                        <div className="row">
                            {
                                pi && pi?.lista?.map(e => (
                                    <div className="col-6 pb-3" key={e.id}>
                                        <CameraItem
                                            onClick={() => {
                                                listarLogs(e.id)
                                                setPIEscolhido(e.codigo)
                                            }}
                                            activeExit={activeExit}
                                            descricao={e.descricao}
                                            key={e.id}
                                            codigo={e.codigo}
                                            status={e.status}
                                            id={e.id}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between py-4 bg-white stricker-header">
                            <h3>Atividades</h3>

                            <div className="d-flex align-items-center">
                                <strong className="mr-3">
                                    {PIEscolhido}
                                    {
                                        PIEscolhido === "" && "Escolha uma PI..."
                                    }
                                </strong>
                            </div>
                        </div>
                        {
                            logs?.map(e => (
                                <NoticiaItem
                                    key={e.id}
                                    text={e.descricao}
                                    data={e.criadoEm}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
            <CModal isOpen={modalRegisterPI}
                text="Novo Ponto de Identificação"
                action={
                    <div className="d-flex justify-content-around">
                        <Botao
                            text="Cancelar"
                            onClick={() => setModalRegisterPI(false)}
                            outline
                        />
                        <Botao
                            text="Registrar PI"
                            onClick={() => criarPI()}
                            tema="secondary"
                            load={load}
                        />
                    </div>
                }
            >
                <Input
                    text="Descrição"
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                />
            </CModal>
        </>
    );
}

export default Local;