import React, { useContext } from 'react';
import { CardGhost, NoStyleButton } from '../../Styles/GlobalComponentStyle';
import { FiMoreVertical } from 'react-icons/fi'
import { ThemeContext } from 'styled-components';
import { ConvidadoEmail, ConvidadoNome } from './Style';

function ConvidadoItem({
    nome,
    email,
    id
}) {
    const {secondary} = useContext(ThemeContext);

    return (
        <CardGhost className="d-flex justify-content-between">
            <div>
                <ConvidadoNome>{nome}</ConvidadoNome>
                <ConvidadoEmail>{email}</ConvidadoEmail>
            </div>
            <NoStyleButton>
                <FiMoreVertical color={secondary} size={28} />
            </NoStyleButton>
        </CardGhost>
    );
}

export default ConvidadoItem;