import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    body {
        margin: 0 !important;
        padding: 0 !important;
        box-sizing: border-box;
        font-family: Prompt;
        color: ${p => p.theme.dark};
    }

    h1,h2,h3,h4,h5 {
        font-weight: bold;
    }

    .pigeon-attribution {
        display: none !important;
    }

    .stricker-header {
        top: -20px;
        position: sticky;
        z-index: 2;
    }
`;
