import styled, { css } from 'styled-components';

export const InputStyle = styled.div`
    margin: 12px 0 12px 0;
`;

export const InputText = styled.input`
    color: ${props => props.theme.dark};
    font-weight: bold;
    width: 100%;
    border: solid 2px ${props => props.theme.dark};
    padding: 4px 8px;
    &::placeholder {
        color: ${props => props.theme.dark};
        font-weight: bold;
    }
    outline: none !important;
    border-radius: .42rem;
`;

export const Label = styled.p`
    margin-bottom: 0 !important;
    font-weight: bold;
    color: ${props => props.theme.dark};
`;

//INPUT SEARCH

export const InputSearchContainer = styled.div`
    margin: 4px 0 12px 0;
    display:flex;
`;

export const InputSearchText = styled.p`
    color: ${p => p.theme.dark};
    font-weight: bold;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
`;
export const InputSearchInput = styled.input`
   ${p => p.maxWidth && css` width: 100%;`};
    padding: 4px 8px;
    width: 100%;
    border: solid 2px ${p => p.theme.dark};
    border-radius: .42rem 0 0 .42rem;
    background: #fff;
    color: ${p => p.theme.dark};
    font-weight: bold;
    &::placeholder {
        color: ${p => p.theme.dark};
        font-weight: bold;
    }
    outline: none !important;
    outline-color: transparent !important;
`;
export const InputSearchButton = styled.button`
    background: ${p => p.theme.dark};
    border-color: transparent !important;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 .42rem .42rem 0;
`;
