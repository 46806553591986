import axios from "axios";
import { setupCache } from "axios-cache-adapter";


axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const token = localStorage.getItem('@ATTEND_TOKEN');
axios.defaults.headers.common["Authentication"] = 'Bearer ' + token;
axios.defaults.timeout = 180000;

export const defaultMaxAge = 15 * 60 * 1000

const cache = setupCache({
    maxAge: defaultMaxAge
})

const Api = axios.create({
    baseURL: '/api',
    adapter: cache.adapter,
    cache: {
        maxAge: 0
    }
})

export default Api;