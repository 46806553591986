import React, { useContext, useEffect, useState } from 'react';
import MapaView from '../MapaView/MapaView';
import { SidebarContainer, SidebarSide, ContextSidebar, MainContext, SidebarButton, BtnClose, ImageInstituicao, ContextContainer } from './Style';
import { HiMap } from 'react-icons/hi';
import { FaUsers, FaBars } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import LocalService from '../../Services/LocalService';
import { Marker } from 'pigeon-maps';
import noImage from '../../img/noImage.png'
import SidebarItem from './SidebarItem';
import { BiExit } from 'react-icons/bi'
import Notificacao from '../Notificacao/Index';
import { Validate } from '../../Functions/Validate';
function Sidebar({ children }) {
    const [openContext, setOpenContext] = useState(true);
    const [openSide, setOpenSide] = useState(false);
    const { dark, primary, secondary } = useContext(ThemeContext);
    const [path, setPath] = useState("");
    const history = useHistory();
    const [locais, setLocais] = useState([]);

    function Deslogar() {
        localStorage.clear('@OPA_TOKEN');
        history.replace('/login');
        toast.dismiss();
    }

    useEffect(() => {
        console.log(Validate());
        if (Validate()) {
            LocalService.listarLocais(
                (e) => {
                    if (e) {
                        setLocais(e)
                    }
                }
            )
        }
    }, [])

    useEffect(() => {
        setPath(window.location.pathname);
    }, [window.location.pathname])

    return (
        <SidebarContainer>
            {
                Validate() && <Notificacao />
            }
            <ImageInstituicao>
                <img src={noImage} alt="não Imagem" />
            </ImageInstituicao>
            <SidebarSide open={openSide}>
                <SidebarButton onClick={() => setOpenSide(!openSide)}>
                    {openSide && <IoMdClose size={26} color="#fff" />}
                    {!openSide && <FaBars size={26} color="#fff" />}
                </SidebarButton>

                <SidebarItem
                    path={path}
                    pathCompare="/locais"
                    onClick={() => setOpenContext(true)}
                    openSide={openSide}
                    renderIcon={<HiMap color={path === '/locais' ? primary : "#fff"} size={26} />}
                    text="Locais"
                />
                <SidebarItem
                    path={path}
                    onClick={() => setOpenContext(true)}
                    pathCompare="/convidados"
                    openSide={openSide}
                    renderIcon={<FaUsers color={path === '/convidados' ? primary : "#fff"} size={26} />}
                    text="Convidados"
                />
                <SidebarItem
                    path="w"
                    pathCompare="3"
                    onClick={() => Deslogar()}
                    openSide={openSide}
                    renderIcon={<BiExit size={26} color="#fff" />}
                    text="Deslogar"
                />

            </SidebarSide>
            <MainContext>
                {/* CONTEXT */}
                <ContextContainer open={openContext}>
                    <ContextSidebar>
                        {children}
                    </ContextSidebar>
                    <BtnClose onClick={() => setOpenContext(!openContext)} open={openContext}>
                        {
                            openContext && <AiOutlineArrowLeft color={dark} size={32} />
                        }
                        {
                            !openContext && <AiOutlineArrowRight color={dark} size={32} />
                        }
                    </BtnClose>
                </ContextContainer>

                {/* MAPA */}
                <div>
                    <MapaView width={window.innerWidth} height={window.innerHeight} zoom={10} lat={-22.913058} lng={-43.2191721} >

                        {
                            locais?.map(e => (
                                <Marker
                                    key={e.id}
                                    color={secondary}
                                    anchor={[e.latitude, e.longitude]}
                                    width={80}
                                />
                            ))
                        }
                    </MapaView>
                </div>
            </MainContext>
        </SidebarContainer>
    );
}

export default Sidebar;