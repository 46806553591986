import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt')

export function toUTC(data) {
    return moment(data).utc();
}

export function toUTCString(data, format) {
    return moment(data).utc().format(format);
}

export function toLocalTime(date) {
    return moment(date).local().toDate()
}

export function toLocalTimeString(date, format = "DD/MM/YYYY", isFromNow = false) {

    if (isFromNow) {
        return moment(date).local().fromNow();
    } else {
        return moment(date).local().format(format)
    }
}

export function dateToString(date, format) {
    return moment(date).format(format)
}

export function getTimezoneLocal() {

    const data = moment.parseZone(new Date()).utcOffset() / 60;
    return data
}