import React from 'react';
import { ModalContainer, ModalContext, ModalTitle, ModalFooter } from './Style';

function CModal({
  isOpen,
  children,
  text,
  action
}) {
  return (
    <ModalContainer isOpen={isOpen}>
      <ModalContext>
        <ModalTitle>{text}</ModalTitle>
        {children}
        <ModalFooter>
          {action}
        </ModalFooter>
      </ModalContext>
    </ModalContainer>
  );
}

export default CModal;