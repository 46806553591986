import { toast } from "react-toastify";
import Api from "./Api";

class InstituicaoService {
    getInstituicao(result) {
        Api.get('Instituicao/Inst/getInstituicao')
            .then(res => {
                console.log(res.data);
                result(res.data);
            })
            .catch(err => {
                console.log(err.response);
            })
    }

    ExportarLogs(instituicaoID) {
        Api.get(`/Instituicao/Inst/Export/${instituicaoID}`)
            .then(res => {
                console.log(res);
                window.open(res.request.responseURL, "_blank")
            })
    }


    criarInstituicao(nome, email, emailDono, result, error) {
        const data = {
            nome,
            email,
            emailDono
        }
        Api.post("/Instituicao/Auth/SignUp", data)
            .then(res => {
                result();
            })
            .catch(res => {
                error();
                if(res?.response.data) {
                    toast.error(res.response.data);
                }
            })
    }
}

export default new InstituicaoService();