import styled, { css } from 'styled-components';

export const ButtonStyle = styled.button`
    border-radius: .32rem ;
    border: solid 2px transparent;
    background: #fff;
    position: relative;
    display: flex;
    align-items: center;
    outline: none !important;
    margin: 12px 0;
    ${p => p.disable && css`
        cursor: default !important;
        opacity: .8 !important;
    `}
    padding: 6px 12px;
    ${props => props.tema === 'primary' && css`
        ${props.outline ? css`
            background: #fff !important;
            border-color: ${props.theme.primary};
            color: ${props.theme.primary};
        ` : css`
            background: ${props.theme.primary} !important;
            color: #fff !important;
        `}
    `}
     ${props => props.tema === 'secondary' && css`
        ${props.outline ? css`
            background: #fff !important;
            border-color: ${props.theme.secondary};
            color: ${props.theme.secondary};
        ` : css`
            background: ${props.theme.secondary} !important;
            color: #fff !important;
        `}
    `}
     ${props => props.tema === 'dark' && css`
        ${props.outline ? css`
            background: #fff !important;
            border-color: ${props.theme.dark};
            color: ${props.theme.dark};
        ` : css`
            background: ${props.theme.dark} !important;
            color: #fff !important;
        `}
    `}
`;

export const ButtonText = styled.span`
    ${p => p.load && css`
        opacity: 0;
    `}
`;

export const BotaoPrincipalContainer = styled.button`
    padding: 20px;
    border-radius: 180px;
    border: solid 2px ${p => p.theme.dark};
    background: #fff;
    margin-bottom: 12px;

    display: flex;
    width: 100%;
    align-items: center;
`;

export const BotaoPrincipalText = styled.span`
    font-weight: bold;
    margin-left: 12px;
    font-size: 1.2rem;
    color: ${p => p.theme.dark};
`;

export const VoltarContainer = styled.button`
    background: ${p => p.theme.secondary};
    border: none !important;
    outline: none !important;
    outline-color: transparent !important;
    border-radius: 99rem;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: fixed;
    bottom: 32px;
    right: 32px;
`;

export const Spinner = styled.div`
    display: inline-flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;

    .rotate {
        @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg) ;
        }
    }
    animation: rotate .8s infinite !important;
    }
`;