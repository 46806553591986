import Api from './Api'

class PIService {
    registrarPI(localId, Descricao, result, error) {
        const data = {
            localId,
            Descricao
        }
        Api.post("/PI/PIs/CriarPI", data)
            .then(res => {
                result();
                window.location.reload();
            })
            .catch(err => {
                error();
            })
    }

    listarPIs(id, result) {
        Api.get(`/PI/PIs/ListarPIs/${id}`)
            .then((res) => {
                result(res.data);
            })
            .catch(err => {
            })
    }

    excluirPI(id, result, error) {
        Api.delete(`/PI/PIs/ExcluirPI/${id}`, {
            cache: {
                maxAge: 0
            }
        })
            .then(() => {
                result();
            })
            .catch(() => {
                error();
            })
    }
}

export default new PIService();