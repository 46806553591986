import React, { useContext } from 'react';
import { ButtonStyle, Spinner, ButtonText } from './Styles';
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { ThemeContext } from 'styled-components';

const Botao = ({
    tema = "primary",
    text,
    outline = false,
    onClick,
    load
}) => {
    const { primary, secondary, dark } = useContext(ThemeContext);
    return (
        <ButtonStyle tema={tema} disable={load} outline={outline} onClick={onClick}>
            <ButtonText load={load}>{text}</ButtonText>
            {
                load && (
                    <Spinner>
                        <AiOutlineLoading3Quarters className="rotate" color={
                            outline && tema === 'primary' ? primary : (
                                outline && tema === 'secondary' ? secondary : (
                                    outline && tema === 'dark' ? dark : "#fff"
                                )
                            )
                        } />
                    </Spinner>
                )
            }
        </ButtonStyle>
    );
}

export default Botao;