import styled from 'styled-components';

export const LocalContainer = styled.button`
    background: ${p => p.theme.secondary};
    width: 100%;
    position: relative;
    overFlow: hidden;
    outline: none !important;
    outline-color: transparent !important;
    border-radius: .64rem;
    padding: 20px;
    border: none !important;
    text-align: left;
    margin-bottom: 20px;
    z-index: 1;
    box-shadow: 0px 4px 16px rgba(0,0,0, .12);
`;
export const LocalNome = styled.p`
    color: #fff;
    font-size: 1.4rem;
    margin: 6px;
`;
export const LocalLocalidade = styled.small`
    color: #fff;
    opacity: .8;
`;
export const LocalStatus = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items:center;
`;
export const LocalBadge = styled.div`
    color: #fff;
    background: ${p => p.theme.secondary};
    padding: 10px 12px;
    border-radius: 99px;
    width: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px !important;
`;

export const IconBackground = styled.div`
    position: absolute;
    left: 0;
    bottom: -15px;
    opacity: .32;
    z-index: -1;
`