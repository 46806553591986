import React, { useState } from 'react';
import { toast } from 'react-toastify';
import InstituicaoService from '../../Services/InstituicaoService';
import Botao from '../Botao/Index';
import CModal from '../CModal/Index';
import Input from '../Input/Index';
function ModalCriarInstituicao({ open, cancelFunc }) {
    const [nome, setNome] = useState();
    const [email, setEmail] = useState();
    const [emailDono, setEmailDono] = useState();
    const [load, setLoad] = useState(false);

    function CriarInstituicao() {
        setLoad(true);
        console.log({
            "nome": nome ,
            "email": email ,
            "dono": emailDono
        })
        if (nome === undefined || email === undefined || emailDono === undefined) {
            toast.error("Preenche todos os campos.")
            setLoad(false);
        } else {
            InstituicaoService.criarInstituicao(
                nome,
                email,
                emailDono,
                () => {
                    setLoad(false);
                    cancelFunc()
                    toast.success("Instituição criada com sucesso!");
                },
                () => {
                    setLoad(false);
                }
            )
        }
    }

    return (
        <CModal isOpen={open} text="Criar instituição">
            <Input
                text="Nome da Instituição"
                value={nome}
                onChange={(text) => setNome(text.target.value)}
            />
            <Input
                text="Email"
                value={email}
                onChange={(text) => setEmail(text.target.value)}
            />
            <Input
                text="Email do dono"
                value={emailDono}
                onChange={(text) => setEmailDono(text.target.value)}
            />
            <div className="d-flex justify-content-between">
                <Botao
                    text="Cancelar"
                    outline
                    tema="secondary"
                    onClick={cancelFunc}
                />
                <Botao
                    text="Registrar Instituição"
                    onClick={() => CriarInstituicao()}
                    load={load}
                />
            </div>
        </CModal>
    );
}

export default ModalCriarInstituicao;