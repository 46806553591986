import React, { useState } from 'react';
import { AuthContainer } from '../../Styles/StylePages/AuthStyle';
import Input from '../../Components/Input/Index';
import Botao from '../../Components/Botao/Index';
import { toast } from 'react-toastify';
import ModalCriarInstituicao from '../../Components/ModalCriarInstituicao/Index';
import img from '../../img/image-Admin.png';

function Admin() {
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");
  const [modalInst, setModalInst] = useState(false);

  function Entrar() {
    console.log({
      login,
      senha
    })
    if (login === "marcio.henryStore@gmail.com" && senha === "Henry#2021") {
      setModalInst(true);
    } else {
      toast.error("Login ou senha incorreto.")
    }
  }

  return (
    <>
      <AuthContainer background="#FEFCFF">
        <div className="container">
          <div className="row">
            <div className="col-4">
              <h3 className="text-dark">Admin</h3>
              <Input
                text="Login"
                onChange={(text) => setLogin(text.target.value)}
                value={login}
              />
              <Input
                text="Senha"
                onChange={(text) => setSenha(text.target.value)}
                value={senha}
                autoComplete="off"
                type="password"
              />
              <div className="d-flex">
                <Botao
                  text="Entrar"
                  onClick={() => Entrar()}
                />
              </div>
            </div>
            <div className="col-8">
              <img src={img} alt="admin" className="w-100" />
            </div>
          </div>
        </div>
      </AuthContainer>
      <ModalCriarInstituicao
        open={modalInst}
        cancelFunc={() => setModalInst(false)}
      />
    </>
  );
}

export default Admin;