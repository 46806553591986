import Api from "./Api";

class AuthService {
    Logar(Login, Senha, result, error) {
        const data = {
            Login: Login,
            Senha: Senha
        }
        Api.post("/Instituicao/Auth/SignIn", data)
            .then(res => {
                localStorage.setItem('@ATTEND_TOKEN', res.data.jwtToken);
                Api.defaults.headers.common["Authentication"] = 'Bearer ' + res.data.jwtToken;
                result();
            })
            .catch(err => {
                error();
                alert(err.response?.data);
            })
    }
}

export default new AuthService();