import styled from 'styled-components';

export const NoticiaContainer = styled.div`
    background: #e9e9e9;
    padding: 20px;
    border-radius: .46rem;
    position: relative;
    margin: 20px 0;

`;

export const NoticiaContext = styled.p`
  color: ${p => p.theme.dark};
  font-weight: medium;
`;

export const NoticiaData = styled.small`
    position:absolute;
    bottom:10px;
    right:10px;
    font-size: .72rem;
    opacity:.6;
`;
