import { motion } from 'framer-motion';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { ThemeContext } from 'styled-components';
import { SidebarButton } from './Style';

function SidebarItem({
    openSide,
    path,
    pathCompare,
    onClick,
    text,
    renderIcon
}) {
    const history = useHistory();
    const { primary } = useContext(ThemeContext);
    const variantButton = {
        hidden: {
        },
        show: {
        }
    }

    const variantP = {
        hidden: {
            opacity: 0,
            left: 64
        },
        show: {
            opacity: 1,
            left: 32
        }
    }

    const variantIcon = {
        hidden: {
        },
        show: {
            left: 0
        }
    }


    return (
        <SidebarButton onClick={() => {
            path !== pathCompare && history.push(pathCompare);
            onClick && onClick()
        }} >
            <motion.button
                style={{
                    width: "100%",
                    height: 64,
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    justifyContent: "center",
                    background: "transparent",
                    border: "none"
                }}
                variants={variantButton}
                initial="hidden"
                animate={openSide ? "show" : "hidden"}
            >
                <motion.div
                style={{
                    position: 'absolute'
                }}
                    variants={variantIcon}
                    initial="hidden"
                    animate={openSide ? "show" : "hidden"}
                >
                    {renderIcon}
                </motion.div>
                <motion.p
                    style={{
                        color: path === pathCompare ? primary : "#fff",
                        fontSize: 14,
                        display: "block",
                        marginBottom: 0,
                        position: 'absolute',
                        marginRight: 6,
                        flexWrap: 'nowrap',
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"

                    }}
                    variants={variantP}
                    initial="hidden"
                    animate={openSide ? "show" : "hidden"}
                >
                    {text}
                </motion.p>
            </motion.button>
        </SidebarButton>
    );
}

export default SidebarItem;