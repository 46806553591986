import React from 'react';
import { InputStyle, InputText, Label } from './Style';

const Input = ({
    value,
    onChange,
    text,
    autoComplete,
    type
}) => {
  return (
    <InputStyle>
    <Label>{text}</Label>
        <InputText type={type} value={value} placeholder="..." autoComplete={autoComplete} onChange={onChange} />
    </InputStyle>
  );
}

export default Input;