import styled, { css } from 'styled-components';

export const AuthContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    ${p => p.background && css`
        color: ${p.background} !important;
    `}
    height: 100vh;
    width: 100%;
`;
