import React, { useContext } from 'react';
import { AiFillCamera } from 'react-icons/ai'
import { HiMap } from 'react-icons/hi';
import { ImUsers } from 'react-icons/im'
import { ThemeContext } from 'styled-components';
import { LocalBadge, LocalContainer, LocalLocalidade, LocalNome, LocalStatus, IconBackground } from './Style';

function LocalItem({
    nome,
    localidade,
    cameras = 0,
    // usuarios = 0,
    onClick = () => { }
}) {
    const { dark } = useContext(ThemeContext);
    return (
        <LocalContainer onClick={onClick}>
            <LocalStatus>
                <LocalBadge>
                    {cameras}
                    <AiFillCamera color="#fff" size={18} />
                </LocalBadge>
                {/* <LocalBadge>
                    {usuarios}
                    <ImUsers color="#fff" size={18} />
                </LocalBadge> */}
            </LocalStatus>
            <LocalNome>{nome}</LocalNome>
            <LocalLocalidade>{localidade}</LocalLocalidade>
            <IconBackground>
                <HiMap size={164} color={dark} />
            </IconBackground>
        </LocalContainer>
    );
}

export default LocalItem;