import React from 'react';
import { toLocalTimeString } from '../../Functions/Date';
import { NoticiaContainer, NoticiaContext, NoticiaData } from './Style';

function NoticiaItem({
    text,
    data
}) {
  return (
      <NoticiaContainer>
          <NoticiaContext>{text}</NoticiaContext>
          <NoticiaData>{toLocalTimeString(data, 'DD/MM/YYYY HH:mm', true)}</NoticiaData>
      </NoticiaContainer>
  )
}

export default NoticiaItem;