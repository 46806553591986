import styled, { css } from 'styled-components';

export const ModalContainer = styled.div`
    ${p => !p.isOpen && css`
        display: none !important;
    `}
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: #0004;
    z-index: 3;
`;

export const ModalContext = styled.div`
    position: fixed;
    z-index: 4;
    background: #fff;
    width: 500px;
    padding: 20px;
    border-radius: .64rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const ModalTitle = styled.h5`
    font-weight:bold;
    padding-bottom: 20px;
    padding: 8px;
`;

export const ModalFooter = styled.div`
    width: 100%;
`;