import { Toast } from "bootstrap";
import { toast } from "react-toastify";
import { Validate } from "../Functions/Validate";
import Api from "./Api";

class LocalService {
    listarLocais(result) {
        if (Validate()) {
            Api.get('/Instituicao/Local/Locais')
                .then(res => {
                    result(res?.data);
                })
                .catch(err => {
                    console.log(err.response)
                })
        }
    }

    getEnderecoPeloCEP(cep, result) {
        fetch(`https://viacep.com.br/ws/${cep}/json/`)
            .then(res => res.json()).then((res) => {
                result(res);
            })
    }

    registrarLocal(Nome, CEP, Logradouro, Localidade, Estado, Complemento, DDD, Telefone, Bairro, Numero, Latitude, Longitude, result, error) {
        const data = {
            Nome,
            CEP,
            Logradouro,
            Localidade,
            Estado,
            Complemento,
            DDD,
            Telefone,
            Bairro,
            Numero,
            Latitude,
            Longitude
        }
        console.log({ '/Instituicao/Local/RegistroLocal(request)': data })
        if (Nome === "" || CEP === "" || Logradouro === "" || Estado === "" || DDD === "" || Telefone === "" || Bairro === "" || Numero === "") {
            toast.error("Os campos precisam ser preenchidos.")
            error();
        } else {
            Api.post("/Instituicao/Local/RegistroLocal", data)
                .then(res => {
                    result();
                    window.location.reload();
                })
                .catch(err => {
                    console.log({
                        "/Instituicao/Local/RegistroLocal(catch)": err
                    })
                    error();
                })
        }
    }

    getLocal(Id, result, error) {
        const data = {
            Id: Id
        }
        console.log({
            '/Instituicao/Local/Local(request)': data
        })
        Api.get(`/Instituicao/Local/Local/${Id}`)
            .then(res => {
                result(res.data);
            })
            .catch(err => {
                error();
            })
    }
}

export default new LocalService();