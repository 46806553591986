import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Botao from '../../Components/Botao/Index';
import LocalItem from '../../Components/LocalItem/Index';
import InstituicaoService from '../../Services/InstituicaoService';
import LocalService from '../../Services/LocalService';
const Locais = () => {
  const history = useHistory()
  const [locais, setLocais] = useState([]);
  const [instituicao, setInstituicao] = useState();

  function ExportarLogs() {
    InstituicaoService.ExportarLogs(instituicao.instituicaoID);
  }

  useEffect(() => {
    LocalService.listarLocais(
      (e) => {
        if (e) {
          setLocais(e)
        }
      }
    )

    InstituicaoService.getInstituicao(
      (e) => setInstituicao(e)
    )
  }, [])

  return (
    <>
      <div>
        <h2>{instituicao?.instituicaoNome}</h2>
        <p className="bg-dark-gray">{instituicao?.numeroUsuarios} usuários</p>
      </div>
      <div className="d-flex justify-content-between">
        <h4 className="pt-3">Locais</h4>
        <div className="d-flex">
          <div className="mr-2">
          <Botao
            text="Exportar logs"
            onClick={() => ExportarLogs()}
            tema="dark"
          />
          </div>
          <div className="ml-2">
          <Botao
            text="Novo local"
            onClick={() => history.push('/novolocal')}
            />
            </div>
        </div>
      </div>
      {
        locais?.map(e => (
          <LocalItem
            key={e.id}
            nome={e.nome}
            localidade={e.localidade}
            cameras={e.pIs}
            usuarios={0}
            onClick={() => history.push(`/local/${e.id}`)}
          />
        ))
      }
    </>
  );
}

export default Locais;