import styled from 'styled-components';

export const BGContext = styled.div`
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #F9F9F9 !important;
    z-index:-1;
`;

export const TitleAbbsolute = styled.h3`
    position: absolute;
    top: 20px;
    left: 20px;
    z-index:2;
    font-weight:bold;
`

export const CardGhost = styled.div`
    padding: 20px;
    border-radius: .42rem;
    background: #e4e4e4;
`;

export const NoStyleButton = styled.button`
    background: transparent !important;
    border: none !important;
    outline: none !important;
    outline-color: transparent;
`;

export const Contact = styled.div`
    display: flex;
    padding: 20px 20px 20px 0;
    align-items:center;

    p {
        font-weight:bold;
        margin-bottom:0;
        margin-left:8px;
    }
`;

export const Scroll = styled.div`
    overflow-y: auto;
`;